import * as React from 'react'
import Layout from '../components/layout'
import ContentMargins from '../components/ContentMargins'
import { Button } from '../components/Button'


const NotFoundPage = () => (
  <Layout>
    <ContentMargins style={{marginTop: '50px'}}>
      <h1>
        Diese Seite existiert nicht.
      </h1>

      <Button to={"/"}>
        Zur Startseite
      </Button>
    </ContentMargins>
  </Layout>
)

export default NotFoundPage
